/* You can add global styles to this file, and also import other style files */

@font-face
{
    font-family: 'Roboto Medium';
    src: url('assets/fonts/Roboto-Medium.ttf');
}

@font-face
{
    font-family: 'Roboto Light';
    src: url('assets/fonts/Roboto-Light.ttf');
}
@font-face
{
    font-family: 'Roboto Regular';
    src: url('assets/fonts/Roboto-Regular.ttf');
}

html, body { height: 100%; }
body { margin: 0; font-family: 'Roboto Light', "Helvetica Neue", sans-serif; }


@media only screen and (min-width:991px) and (max-width:1300px){
  html{font-size: 12.5px;}
  }
  .top-bar{
    // background-color:#4d9301;
    // margin: -24px;

    .heading {
      text-align: center;
    }
  }
  .example-tooltip-red {
    background: #b71c1c;
    }

    app-dashboard {
      .mat-tab-header{border-bottom: none; margin-top: -10px;
      }.mat-ink-bar{background-color: #4d9301 !important;}
      .mat-tab-label{background: #f8f6f6;opacity: 1;color: #000000;     height: 36px;}
      .mat-tab-label-active{background: #4d9301;border-radius: 8px 8px 0px 0px;opacity: 1;color: #ffffff;height: 36px;}


      }

      app-accounting,app-configurations{

        .card{
          border-top-left-radius: 10px !important;
          border-top-right-radius: 7px !important;
          border-left: 4px solid #ffc107;
          border-bottom: 1px solid rgba(255,255,255,0.04);
          // &:last-child{
          //   border-left: 4px solid #4d9301;
          // }
        }

      }

      app-dashboard,app-fleets{
        .card{
          border-top-left-radius: 10px !important;
            border-top-right-radius: 7px !important;
            border-left: 4px solid #0000;
            border-bottom: 1px solid rgba(255,255,255,0.04);

        }
      }


     app-root{
      font-family: 'Roboto Light';
      ion-button{
        font-family: 'Roboto Regular'
      }

      .center{
        margin: auto;
        padding-top: 10px;
        padding-bottom: 10px;
      }

      .loader {
        --b: 10px;  /* border thickness */
        --n: 10;    /* number of dashes*/
        --g: 5deg; /* gap between dashes*/
        --c: #ffffff;   /* the color */

        width: 100px; /* size */
        aspect-ratio: 1;
        border-radius: 50%;
        padding: 1px;
        background: conic-gradient(#0000,var(--c)) content-box;
        -webkit-mask:
          repeating-conic-gradient(#0000 0deg,
             #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
             #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
          radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
                mask:
          repeating-conic-gradient(#0000 0deg,
             #000 1deg calc(360deg/var(--n) - var(--g) - 1deg),
             #0000     calc(360deg/var(--n) - var(--g)) calc(360deg/var(--n))),
          radial-gradient(farthest-side,#0000 calc(98% - var(--b)),#000 calc(100% - var(--b)));
        -webkit-mask-composite: destination-in;
                mask-composite: intersect;
        animation: load 1s infinite steps(var(--n));
      }
      @keyframes load {to{transform: rotate(1turn)}}

     }

      @media print {
        app-root > * { display: none; }
        app-root app-print-layout { display: block; }
      }
